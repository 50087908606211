<template>
  <!--begin::Card-->
  <div>
    <form class="form" novalidate="novalidate" id="topping_form">
      <div class="form-group">
        <label>Name</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          v-model="form.name"
          name="name"
          ref="name"
          placeholder="Topping Name"
        />
        <span class="form-text text-muted"
          >Enter the name of this topping.</span
        >
      </div>
      <div class="form-group">
        <label>Printing Name</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          v-model="form.printing_name"
          name="printing_name"
          ref="printing_name"
          placeholder="Topping Printing Name"
        />
        <span class="form-text text-muted">
          Enter the printing name of this topping.
        </span>
      </div>
      <div class="form-group">
        <label>Price</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          v-model="form.price"
          name="price"
          ref="price"
          placeholder="Topping Price"
        />
        <span class="form-text text-muted">
          Enter the additional cost for this topping.
        </span>
      </div>
      <div class="form-group">
        <label>Allow Multiple</label>
        <input
          class="ml-3"
          v-model="form.multiple"
          type="checkbox"
        />
      </div>
      <div class="row justify-content-end">
        <button ref="topping_form_submit" class="btn btn-primary mt-3 mr-4">
          Submit
        </button>
      </div>
    </form>
  </div>
  <!--end::Card-->
</template>

<script>
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";

export default {
  name: "ToppingForm",
  props: ["group_id", "id"],
  data() {
    return {
      form: {
        multiple : 0,
        name: "",
        printing_name: "",
        price: "",
        topping_group_id: "",
      },
    };
  },
  mounted() {
    this.form.topping_group_id = this.group_id;
    const topping_form = KTUtil.getById("topping_form");
    this.fv = formValidation(topping_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Name is required",
            },
          },
        },
        printing_name: {
          validators: {
            notEmpty: {
              message: "Printing Name is required",
            },
          },
        },
        price: {
          validators: {
            notEmpty: {
              message: "Price is required",
            },
          },
        },

      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      const form = this.form;
      // set spinner to submit button
      const submitButton = this.$refs["topping_form_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      console.log(form);

      var url = "business/menu/toppings";

      if (this.id) {
        url = "business/menu/toppings/" + this.id;
        form._method = "PUT";
      }

      ApiService.post(url, form)
        .then(({ data }) => {
          if (data.data)
          {
            if (data.data.id > 0)
            {
              Swal.fire("Information!", "Topping Item saved successfully !", "success");
              this.$emit("success");
            }
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "",
            text: "Form Error",
            icon: "error",
            confirmButtonClass: "btn btn-primary",
            heightAuto: false,
          });
        });

      //Remove Spineer
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    if (this.id) this.get(this.id);
  },
  methods: {
    get(id) {
      ApiService.get("business/menu/toppings", id).then(({ data }) => {
        let topping = data.data;
        this.form.name = topping.name;
        this.form.printing_name = topping.printing_name;
        this.form.price = topping.price;
        this.form.topping_group_id = topping.topping_group_id;
        this.form.multiple = topping.multiple;
      });
    },
  },
};
</script>
