<template>
  <!--begin::Card-->
  <div>
    <form id="tg_form" class="form" novalidate="novalidate">
      <div class="form-group">
        <label>Name</label>
        <input
          ref="name"
          v-model="form.name"
          class="form-control form-control-solid form-control-lg"
          name="name"
          placeholder="Group Name"
          type="text"
        />
        <span class="form-text text-muted"
          >Enter a name for this group of toppings.</span
        >
      </div>
      <div class="form-group">
        <label>Printing Name</label>
        <input
          ref="printing_name"
          v-model="form.printing_name"
          class="form-control form-control-solid form-control-lg"
          name="printing_name"
          placeholder="Group Printing Name"
          type="text"
        />
        <span class="form-text text-muted">
          Enter a printing name for this group of toppings.
        </span>
      </div>
      <div class="form-group">
        <label>Required</label>
        <br />
        <input
          ref="topping_item_required"
          v-model="form.topping_item_required"
          name="topping_item_required"
          placeholder="Toppings required"
          type="checkbox"
        />
        <span class="form-text text-muted"
          >Please choose the toppings required</span
        >
      </div>
      <div class="form-group">
        <label>Required Items</label>
        <input
          ref="items_required"
          v-model="form.items_required"
          class="form-control form-control-solid form-control-lg"
          name="items_required"
          placeholder="Topping Required Items"
          type="number"
        />
        <span class="form-text text-muted"
          >Please choose the number of required items</span
        >
      </div>
      <div class="row justify-content-end">
        <button ref="tg_form_submit" class="btn btn-primary mt-3 mr-4">
          Submit
        </button>
      </div>
    </form>
  </div>
  <!--end::Card-->
</template>

<script>
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";

export default {
  name: "ToppingGroupForm",
  props: ["id"],
  data() {
    return {
      form: {
        id: "",
        name: "",
        printing_name: "",
        items: [],
        topping_item_required: false,
        items_required: 0
      }
    };
  },
  mounted() {
    const tg_form = KTUtil.getById("tg_form");
    this.fv = formValidation(tg_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Name is required"
            }
          }
        },
        printing_name: {
          validators: {
            notEmpty: {
              message: "Description is required"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      const form = this.form;
      // set spinner to submit button
      const submitButton = this.$refs["tg_form_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var url = "business/menu/topping-groups";

      if (this.id) {
        url = "business/menu/topping-groups/" + this.id;
        form._method = "PUT";
      }
      // if toppings are required
      if (this.form.topping_item_required) {
        if (this.form.items_required === 0) {
          Swal.fire({
            title: "Data Error",
            text: "Number of toppings required must be entered",
            icon: "error",
            confirmButtonClass: "btn btn-primary",
            heightAuto: false
          });
          return;
        }
      }

      ApiService.post(url, form)
        .then(({ data }) => {
          if (data.data)
          {
            if (data.data.id > 0)
            {
              Swal.fire("Information!", "Topping Group saved successfully !", "success");
              this.$emit("success");
            }
          }
          
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "",
            text: "Form Error",
            icon: "error",
            confirmButtonClass: "btn btn-primary",
            heightAuto: false
          });
        });

      //Remove Spineer
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    if (this.id) this.get(this.id);
  },
  methods: {
    get(id) {
      ApiService.get("business/menu/topping-groups", id).then(({ data }) => {
        let tg = data.data;
        this.form.id = tg.id;
        this.form.name = tg.name;
        this.form.printing_name = tg.printing_name;
        this.form.items = tg.items;
        this.form.topping_item_required = tg.required;
        this.form.items_required = tg.required_count;
      });
    }
  }
};
</script>
